/* --- Global --- */
import {useEffect, useState} from 'react';
import {useWindowSize} from '@react-hook/window-size';

/* --- Style --- */
const styleMenuItem = {
  fontSize: 2,
  my: 1,
  flexDirection: 'column',
};
const styleMenuItemHover = {
  // color: 'white',
  opacity: 1,
  transform: 'scale(1.025)',
};
const styleMenuItemChildren = {
  fontSize: 1,
  my: 1,
  flexDirection: 'column',
};

/* --- Component --- */
const RegionAside = ({sx, ...props}) => {
  const [width, height] = useWindowSize();
  const [isMobile, setIsMobile] = useState();
  const [isMenuOpen, setMenuOpen] = useState(true);

  const toggleMenu = () => setMenuOpen(!isMenuOpen);

  console.log(width, height);

  useEffect(() => {
    if (width < 820) {
      setIsMobile(true);
      setMenuOpen(false);
    } else if (width > 821) {
      setIsMobile(false);
      setMenuOpen(true);
    }
  }, []);

  useEffect(() => {
    if (width < 820) {
      setMenuOpen(false);
      setIsMobile(true);
    } else if (width > 821) {
      setMenuOpen(true);
      setIsMobile(false);
    }
  }, []);

  useEffect(() => {
    if (width < 820) {
      setMenuOpen(false);
      setIsMobile(true);
    } else if (width > 821) {
      setMenuOpen(true);
      setIsMobile(false);
    }
  }, [width]);

  return (
    <Atom.Flex column sx={{variant: 'regions.aside'}}>
      {/* Branding : Area  */}
      {isMobile ? (
        <Atom.Flex
          between
          sx={{
            bg: 'smoke',
            boxShadow: 1,
            p: 4,
            textAlign: 'center',
            width: '100%',
          }}>
          <Atom.Flex
            center
            column
            // gradient="neutral"
          >
            <Molecule.Link to="/">
              <Atom.Heading lg heavy sx={{mb: 0}}>
                EthQuest
              </Atom.Heading>
            </Molecule.Link>
          </Atom.Flex>
          <Atom.Span pointer onClick={toggleMenu}>
            <Atom.Image
              src="https://image.flaticon.com/icons/svg/149/149165.svg"
              sx={{width: 26}}
            />
          </Atom.Span>
        </Atom.Flex>
      ) : (
        <Atom.Flex
          center
          column
          // gradient="neutral"
          sx={{
            bg: 'smoke',
            boxShadow: 1,
            // color: 'white',
            p: 4,
            textAlign: 'center',
          }}>
          <Molecule.Link to="/">
            <Atom.Heading lg heavy sx={{mb: 0}}>
              EthQuest
            </Atom.Heading>
          </Molecule.Link>
        </Atom.Flex>
      )}

      {/* Menu : Area  */}
      {isMenuOpen && (
        <Atom.Box sx={{px: 4, py: 3, width: '100%'}}>
          <Molecule.Menu
            direction="column"
            sx={styleMenuItem}
            hover={styleMenuItemHover}
            sxChildren={styleMenuItemChildren}
            sxChild={{transition: 'all 0.2s'}}
            items={[
              {
                label: 'Requests',
                to: '/dashboard/requests',
                image: (
                  <Atom.Image
                    src="https://image.flaticon.com/icons/svg/744/744735.svg"
                    sx={{width: 22, mr: 3}}
                  />
                ),
              },
              {
                label: 'Funded',
                to: '/dashboard/funded',
                image: (
                  <Atom.Image
                    src="https://image.flaticon.com/icons/svg/744/744760.svg"
                    sx={{width: 22, mr: 3}}
                  />
                ),
              },
              // {
              //   label: 'Latest',
              //   to: '/moments/latest',
              //   image: (
              //     <Atom.Image
              //       src="https://image.flaticon.com/icons/svg/744/744758.svg"
              //       sx={{width: 22, mr: 3}}
              //     />
              //   ),
              // },
              // {
              //   label: 'Community',
              //   to: '/community',
              //   image: (
              //     <Atom.Image
              //       src="https://image.flaticon.com/icons/svg/744/744757.svg"
              //       sx={{width: 22, mr: 3}}
              //     />
              //   ),
              // },
            ]}
          />
        </Atom.Box>
      )}
    </Atom.Flex>
  );
};

export default RegionAside;
