"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.extension = void 0;

var hooks = _interopRequireWildcard(require("./hooks"));

var actions = _interopRequireWildcard(require("./actions"));

var selectors = _interopRequireWildcard(require("./selectors"));

var types = _interopRequireWildcard(require("./types"));

var _reducer = _interopRequireDefault(require("./reducer"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var extension = {
  name: "ethers-react-global",
  actions,
  hooks,
  selectors,
  types,
  reducer: _reducer.default,
  initialState: {
    activity: {
      deploy: {},
      messages: {},
      signatures: {},
      transactions: {}
    },
    requests: {
      deploy: [],
      messages: [],
      signatures: [],
      transactions: []
    },
    store: {
      contracts: []
    }
  }
};
exports.extension = extension;