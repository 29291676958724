"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.walletSignMessageRequest = void 0;

var _utilities = require("../utilities");

var _types = require("../types");

var walletSignMessageRequest = dispatch => (_ref) => {
  var {
    message,
    delta
  } = _ref;
  return dispatch({
    type: _types.WALLET_SIGN_MESSAGE_REQUEST,
    payload: message,
    id: delta || (0, _utilities.hashCode)(message)
  });
};

exports.walletSignMessageRequest = walletSignMessageRequest;