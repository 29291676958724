"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSelectContractByAddress = void 0;

var _react = require("react");

var _system = require("@ethers-react/system");

/**
 * @function useSelectContractByAddress
 */

/* --- Global --- */

/* --- useSelectContractByAddress : Effect --- */
var useSelectContractByAddress = address => {
  /* --- Application : State --- */
  var ethers = (0, _system.withEthers)();
  /* --- Component : State --- */

  var [contract, setContract] = (0, _react.useState)(undefined);
  var [isFound, setIsFound] = (0, _react.useState)();
  (0, _react.useEffect)(() => {
    if (ethers.contracts) {
      var _contract = Object.keys(ethers.contracts).filter(contract => ethers.contracts[contract].address === address);

      if (_contract.length > 0) {
        setContract(ethers.contracts[_contract[0]]);
        setIsFound(true);
      } else {
        setIsFound(false);
      }
    }
  }, [ethers.contracts]);
  return {
    contract,
    isFound
  };
};

exports.useSelectContractByAddress = useSelectContractByAddress;