"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.enhanceActions = void 0;

/**
 * @function enhanceActions
 * @description Wrap extenion actions with dispatch
 */
var enhanceActions = (list, state, dispatch) => {
  var actions = {};
  Object.keys(list).forEach(action => {
    actions[action] = list[action](state, dispatch);
  });
  return actions;
};

exports.enhanceActions = enhanceActions;