"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.extensionsInitialize = void 0;

var extensionsInitialize = (extensions, state, dispatch) => extensions.map(extension => {
  return Object.values(extension.hooks).map(effect => effect(state, dispatch));
});

exports.extensionsInitialize = extensionsInitialize;