"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAccountBalance = void 0;

var _react = require("react");

var _ethers = require("ethers");

var _types = require("../types");

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

/* --- Effect --- */
var useAccountBalance = (state, dispatch) => {
  (0, _react.useEffect)(() => {
    if (state.reactive.getAccountBalance && state.address && state.wallet) {
      _asyncToGenerator(function* () {
        try {
          var balance = yield state.wallet.getBalance();
          dispatch({
            payload: {
              bigNumber: balance,
              wei: balance.toString(),
              eth: _ethers.utils.formatEther(balance),
              trimmed: _ethers.utils.formatEther(balance).substring(0, 5)
            },
            type: _types.BALANCE_SET
          });
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [state.wallet, state.address]);
  return true;
};

exports.useAccountBalance = useAccountBalance;