"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useWalletSignTypedMessageV3 = void 0;

var _react = require("react");

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

/* --- Effect --- */
var useWalletSignTypedMessageV3 = props => {
  var [messageUnsigned, setMessageUnsigned] = (0, _react.useState)(undefined);
  var [messageSigned, setMessageSigned] = (0, _react.useState)(undefined);
  var [wallet, setWallet] = (0, _react.useState)(undefined);
  /* --- Error : State --- */

  var [messageSignedError, setMessageSignedError] = (0, _react.useState)(undefined);
  /* --- Initialize --- */

  var init = (wallet, msg) => {
    setMessageUnsigned(msg);
    setWallet(wallet);
  };
  /* --- Reset --- */


  var reset = (wallet, msg) => {
    setMessageUnsigned(undefined);
    setMessageSigned(undefined);
    setWallet(undefined);
  };

  var msgParams = [{
    type: "string",
    name: "Message",
    value: "Hi, Alice!"
  }, {
    type: "uint32",
    name: "A number",
    value: "1337"
  }];
  var domain = [{
    name: "name",
    type: "string"
  }, {
    name: "version",
    type: "string"
  }, {
    name: "chainId",
    type: "uint256"
  }, {
    name: "verifyingContract",
    type: "address"
  }, {
    name: "salt",
    type: "bytes32"
  }];
  var bid = [{
    name: "amount",
    type: "uint256"
  }, {
    name: "bidder",
    type: "Identity"
  }];
  var identity = [{
    name: "userId",
    type: "uint256"
  }, {
    name: "wallet",
    type: "address"
  }];
  var domainData = {
    name: "Horizin",
    version: "2",
    chainId: parseInt(web3.version.network, 10),
    verifyingContract: "0x1C56346CD2A2Bf3202F771f50d3D14a367B48070",
    salt: "0xf2d857f4a3edcb9b78b4d503bfe733db1e3f6cdc2b7971ee739626c97e86a558"
  };
  var message = {
    amount: 100,
    bidder: {
      userId: 323,
      wallet: "0x3333333333333333333333333333333333333333"
    }
  };
  var data = JSON.stringify({
    types: {
      EIP712Domain: domain,
      Bid: bid,
      Identity: identity
    },
    domain: domainData,
    primaryType: "Bid",
    message: message
  });
  /* --- Sign Message :: Effect --- */

  (0, _react.useEffect)(() => {
    if (messageUnsigned && !messageSigned && wallet) {
      _asyncToGenerator(function* () {
        wallet.provider.provider.sendAsync({
          method: "eth_signTypedData_v3",
          params: [wallet._address, data],
          from: wallet._address
        }, (err, result) => {
          if (err) {
            setMessageSignedError(err);
          } else {
            setMessageSigned(result);
          }
        });
      })();
    }
  }, [messageUnsigned, wallet]);
  /* --- Return : Complete --- */

  return {
    init,
    reset,
    messageSigned,
    messageUnsigned,
    error: messageSignedError
  };
};

exports.useWalletSignTypedMessageV3 = useWalletSignTypedMessageV3;