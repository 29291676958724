"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _types = require("./types");

var _status = require("./status");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var reducerActions = (state, action) => {
  switch (action.type) {
    /* ----------------------- */

    /*         Common          */

    /* ----------------------- */
    case _types.ENABLE_REQUEST:
      return _objectSpread({}, state, {
        isEnableRequested: true
      });

    case _types.ENABLE_SUCCESS:
      return _objectSpread({}, state, {
        isEnableSuccess: true,
        isEnableRequested: false
      });

    case _types.ENABLE_FAILURE:
      return _objectSpread({}, state, {
        isEnableRequested: false,
        isEnableSuccess: false
      });

    case _types.PROVIDER_SET:
      return _objectSpread({}, state, {
        provider: action.payload
      });

    case _types.PROVIDER_SET_MULTIPLE:
      return _objectSpread({}, state, {
        providers: action.payload
      });

    case _types.PROVIDER_SET_STATUS:
      return _objectSpread({}, state, {
        providerStatus: action.payload
      });

    case _types.SET_ADDRESS:
      return _objectSpread({}, state, {
        address: action.payload
      });

    case _types.BALANCE_SET:
      return _objectSpread({}, state, {
        balance: action.payload
      });

    case _types.NONCE_SET:
      return _objectSpread({}, state, {
        nonce: action.payload
      });

    case _types.NETWORK_SET:
      return _objectSpread({}, state, {
        network: action.payload
      });

    case _types.ENS_ADDRESS_SET:
      return _objectSpread({}, state, {
        ensAddress: action.payload
      });

    /* ----------------------- */

    /*         Signer          */

    /* ----------------------- */

    case _types.SIGNER_GET_REQUEST:
      return _objectSpread({}, state);

    case _types.SIGNER_GET_SUCCESS:
      return _objectSpread({}, state, {
        wallet: action.payload
      });

    case _types.SIGNER_GET_FAILURE:
      return _objectSpread({}, state, {
        wallet: false
      });

    /* ----------------------- */

    /*         Wallet          */

    /* ----------------------- */

    case _types.WALLET_PROVIDER_GET_SUCCESS:
      return _objectSpread({}, state, {
        injected: action.payload
      });

    case _types.SET_WALLET:
      return _objectSpread({}, state);

    case _types.SET_WALLET_FAILURE:
      return _objectSpread({}, state);

    case _types.WALLET_SEND_TRANSACTION_REQUEST:
      return _objectSpread({}, state, {
        requests: _objectSpread({}, state.requests, {
          transactions: [...state.requests.transactions, _objectSpread({}, action)]
        })
      });

    case _types.WALLET_SEND_TRANSACTION_SUCCESS:
      return _objectSpread({}, state, {
        requests: _objectSpread({}, state.requests, {
          transactions: []
        }),
        activity: _objectSpread({}, state.activity, {
          transactions: _objectSpread({}, state.activity.transactions, {
            [action.payload.hash]: {
              status: _status.TRANSACTION_SUBMITTED,
              payload: action.payload
            }
          })
        })
      });

    case _types.TRANSACTION_MONITOR_SUCCESS:
      return _objectSpread({}, state, {
        activity: _objectSpread({}, state.activity, {
          transactions: _objectSpread({}, state.activity.transactions, {
            [action.payload.transactionHash]: _objectSpread({}, state.activity.transactions[action.payload.transactionHash], {
              status: _status.TRANSACTION_COMPLETE,
              complete: action.payload
            })
          })
        })
      });

    case _types.WALLET_SEND_TRANSACTION_FAILURE:
      return _objectSpread({}, state, {
        requests: _objectSpread({}, state.requests, {
          transactions: [],
          activity: [...state.activity, {
            status: _status.TRANSACTION_REJECTED,
            payload: action.payload
          }]
        })
      });

    case _types.TRANSACTION_MONITOR_FAILURE:
      return _objectSpread({}, state, {
        activity: {
          transactions: [...state.activity.transactions, _objectSpread({}, state.activity.transactions[action.payload.transactionHash], {
            status: _status.TRANSACTION_REJECTED,
            error: action.payload
          })]
        }
      });

    case _types.WALLET_SIGN_TYPED_MESSAGE_REQUEST:
      return _objectSpread({}, state, {
        store: _objectSpread({}, state.store, {
          messages: [...state.store.messages, _objectSpread({}, action)]
        })
      });

    case _types.WALLET_SIGN_MESSAGE_REQUEST:
      return _objectSpread({}, state, {
        messages: {
          [id]: action.payload
        }
      });

    /* ----------------------- */

    /* Contract Initialize     */

    /* ----------------------- */

    case _types.CONTRACT_INITIALIZE_SUCCESS:
      return _objectSpread({}, state, {
        contracts: _objectSpread({}, state.contracts, {
          [action.id]: _objectSpread({
            api: action.payload
          }, state.contracts[action.payload.address])
        }, state.contacts)
      });

    case _types.INIT_CONTRACT_REQUEST:
      var {
        address,
        contract
      } = action.payload;
      return _objectSpread({}, state, {
        store: _objectSpread({}, state.store, {
          contracts: []
        }),
        contracts: _objectSpread({}, state.contracts, {
          [id]: _objectSpread({
            id,
            address
          }, contract)
        })
      });

    /* ----------------------- */

    /* Contract Deployment     */

    /* ----------------------- */

    case _types.CONTRACT_DEPLOY_REQUEST:
      return _objectSpread({}, state, {
        requests: _objectSpread({}, state.requests, {
          deploy: [...state.requests.deploy, {
            action
          }]
        })
      });

    case _types.CONTRACT_DEPLOY_SUCCESS:
      return _objectSpread({}, state, {
        activity: _objectSpread({}, state.activity, {
          deploy: _objectSpread({}, state.activity.deploy, {
            [action.payload.hash]: {
              status: _status.CONTRACT_DEPLOY_SUBMITTED,
              payload: action.payload
            }
          })
        })
      });

    case _types.CONTRACT_DEPLOY_MONITOR_SUCCESS:
      return _objectSpread({}, state, {
        activity: _objectSpread({}, state.activity, {
          deploy: _objectSpread({}, state.activity.deploy, {
            [action.payload.transactionHash]: _objectSpread({}, state.activity.deploy[action.payload.transactionHash], {
              status: _status.CONTRACT_DEPLOY_COMPLETE,
              complete: action.payload
            })
          })
        })
      });

    case _types.CONTRACT_DEPLOY_FAILURE:
      return _objectSpread({}, state, {
        activity: _objectSpread({}, state.store, {
          deploy: _objectSpread({}, state.activity.deploy, {
            [action.payload.hash]: {
              status: _types.CONTRACT_DEPLOY_FAILURE,
              payload: action.payload
            }
          })
        })
      });

    case _types.CONTRACT_DEPLOY_MONITOR_FAILURE:
      return _objectSpread({}, state, {
        activity: {
          deploy: [...state.activity.deploy, _objectSpread({}, state.activity.deploy[action.payload.transactionHash], {
            status: _status.CONTRACT_DEPLOY_REJECTED,
            error: action.payload
          })]
        }
      });

    default:
      return state;
    // throw new Error(`No Reducer Type Set: ${type}`);
  }
};

var _default = reducerActions;
exports.default = _default;