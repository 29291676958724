"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useNetworkOnChange = void 0;

var _react = require("react");

var _types = require("../types");

/**
 * @function useNetworkOnChange
 * @param {Object} state
 * @param {Object} dispatch
 */

/* --- Global --- */

/* --- Local --- */

/* --- Effect --- */
var useNetworkOnChange = (state, dispatch) => {
  /* --- Account Change : Listen Event --- */
  (0, _react.useEffect)(() => {
    if (state.reactive.watchNetworkOnChange && window.ethereum) {
      window.ethereum.on("networkChanged", networkId => {
        dispatch({
          type: _types.NETWORK_CHANGE_SET,
          payload: networkId
        });
      });
      return true;
    }
  }, []);
};

exports.useNetworkOnChange = useNetworkOnChange;