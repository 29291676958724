"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useGetTransaction = void 0;

var _react = require("react");

var _system = require("@ethers-react/system");

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

/* --- Effect --- */
var useGetTransaction = txHash => {
  /* --- Application : State --- */
  var ethers = (0, _system.withEthers)();
  /* --- Component : State --- */

  var [transactionHash, setTransactionHash] = (0, _react.useState)(txHash);
  var [transactionData, setTransactionData] = (0, _react.useState)();
  /* --- Error : State --- */

  var [error, setError] = (0, _react.useState)();
  /* --- Initialize --- */

  var init = txHash => {
    if (txHash) setTransactionHash(txHash);
  };
  /* --- Sign Message :: Effect --- */


  (0, _react.useEffect)(() => {
    if (ethers.provider && transactionHash) {
      _asyncToGenerator(function* () {
        try {
          var txData = yield ethers.provider.getTransaction(transactionHash);
          setTransactionData(txData);
        } catch (error) {
          setError(error);
        }
      })();
    }
  }, [ethers.provider, transactionHash]);
  /* --- Return : Complete --- */

  return {
    init,
    error,
    transactionData,
    transactionHash
  };
};

exports.useGetTransaction = useGetTransaction;