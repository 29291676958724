"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAccountOnChange = void 0;

var _react = require("react");

var _types = require("../types");

/**
 * @function useAddressSet
 * @param {Object} state
 * @param {Object} dispatch
 */

/* --- Global --- */

/* --- Local --- */

/* --- Effect --- */
var useAccountOnChange = (state, dispatch) => {
  var [address, setAddress] = (0, _react.useState)(undefined);
  /* --- Set Address : Effect --- */

  (0, _react.useEffect)(() => {
    if (state.address !== address) {
      if (address) {
        dispatch({
          payload: address,
          type: _types.SET_ADDRESS
        });
      }
    }
  }, [address]);
  /* --- Selected Address : Listen Event --- */

  (0, _react.useEffect)(() => {
    if (state.reactive.getAccountOnLoad && window.ethereum && window.ethereum.selectedAddress) {
      setAddress(window.ethereum.selectedAddress);
    }
  }, []);
  /* --- Account Change : Listen Event --- */

  (0, _react.useEffect)(() => {
    if (state.reactive.watchAccountOnChange && window.ethereum) {
      window.ethereum.on("accountsChanged", function (accounts) {
        if (accounts[0] !== address) {
          setAddress(accounts[0]);
        }
      });
    }
  }, []);
  return true;
};

exports.useAccountOnChange = useAccountOnChange;