"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userSet = void 0;

var _types = require("../types");

var userSet = (state, dispatch) => payload => dispatch({
  type: _types.USER_SET,
  payload: payload
});

exports.userSet = userSet;