"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.extension = void 0;

var hooks = _interopRequireWildcard(require("./hooks"));

var _reducer = _interopRequireDefault(require("./reducer"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var extension = {
  name: "ethers-react-reactive",
  hooks,
  reducer: _reducer.default,
  initialState: {
    blockCurrent: 0,
    reactive: {
      getAccountBalance: false,
      getAccountOnLoad: false,
      getAccountNonce: false,
      getProviderSigner: false,
      watchAccountBalance: false,
      watchAccountOnChange: false,
      watchAccountNonce: false,
      watchBlockCurrent: false,
      watchNetworkOnChange: false
    }
  }
};
exports.extension = extension;