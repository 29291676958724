"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useBlockMined = void 0;

var _react = require("react");

var _types = require("../types");

/**
 * @function useBlockMined
 * @param {Object} state
 * @param {Object} dispatch
 */

/* --- Global --- */

/* --- Local --- */

/* --- Effect --- */
var useBlockMined = (state, dispatch) => {
  /* --- Account Change : Listen Event --- */
  (0, _react.useEffect)(() => {
    if (state.reactive.watchBlockCurrent && state.provider) {
      state.provider.on("block", block => {
        dispatch({
          type: _types.BLOCK_CURRENT_SET,
          payload: block
        });
      });
    }

    return () => state.provider ? state.provider.removeListener("block") : null;
  }, [state.provider]);
};

exports.useBlockMined = useBlockMined;