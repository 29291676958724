"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.walletSignTransactionRequest = void 0;

var _types = require("../types");

var walletSignTransactionRequest = dispatch => transaction => {
  dispatch({
    type: _types.WALLET_SIGN_TRANSACTION_REQUEST,
    payload: transaction
  });
};

exports.walletSignTransactionRequest = walletSignTransactionRequest;