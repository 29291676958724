export default {
  headerAbsolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    color: '#FFF',
    px: 4,
    py: 2,
    zIndex: 10000,
  },
  header: {
    bg: 'paper',
    boxShadow: 1,
    color: 'text',
    borderBottom: '2px solid #ececec',
    px: 4,
    py: 2,
    zIndex: 100000,
  },
  aside: {
    boxShadow: 3,
    flex: 3,
  },
  footer: {
    bg: 'blue',
    color: 'white',
    boxShadow: 0,
    px: 4,
    py: 5,
    zIndex: 100,
  },
  fill: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};
