import wave from '@assets/images/bump.png';
/* --- Component --- */
const Component = props => {
  return (
    <Atom.Flex column>
      <Showcase />
      <Content />
    </Atom.Flex>
  );
};
export default Component;

const Showcase = props => {
  return (
    <Atom.Flex
      center
      column
      sx={{
        color: 'white',
        pt: 6,
        pb: 6,
        width: '100%',
        zIndex: 1000,
      }}>
      <Atom.BackgroundGradient gradient="bluePurple" direction={0} />
      <Atom.Absolute sx={{bottom: 0, left: 0, right: 0}}>
        <Atom.Image src={wave} sx={{height: 80, width: '100%'}} />
      </Atom.Absolute>
      <Atom.Container
        sx={{
          pt: 2,
          pb: 4,
          maxWidth: ['100%', '100%', 620, 780],
        }}>
        <Atom.Box sx={{flex: 2, textAlign: 'center'}}>
          <Atom.Heading
            sx={{
              fontSize: [5, 5, 5, 6],
            }}>
            Quest Not Found
          </Atom.Heading>
        </Atom.Box>
      </Atom.Container>
    </Atom.Flex>
  );
};

const Content = props => {
  return (
    <Atom.Box>
      <Atom.Container
        sx={{
          pt: 2,
          pb: 4,
          maxWidth: ['100%', '100%', 620, 780],
        }}>
        <Atom.Flex center column>
          <Atom.Heading as="h5" xl sx={{textAlign: 'center'}}>
            This is not the quest
            <br />
            you are looking for Padawan
          </Atom.Heading>
          <Atom.Heading as="h5" md normal sx={{color: 'blue'}}>
            Perhaps we can help you find the right quest though?
          </Atom.Heading>
        </Atom.Flex>
        <Atom.HorizontalRule
          sx={{
            mt: 4,
            mb: 4,
            width: 50,
            mx: 'auto',
          }}
        />
        <Atom.Paragraph>
          Aenean scelerisque neque augue, id viverra lectus fringilla iaculis.
          Nulla dolor dui, ultrices et leo ac, suscipit tempus eros. Mauris
          malesuada mattis fringilla. Fusce viverra vulputate leo, eget mollis
          est sollicitudin non. Vestibulum posuere id neque sed efficitur. Duis
          vitae mi non ipsum pellentesque condimentum ac vitae nibh. Morbi
          aliquam odio ac dui varius iaculis. Class aptent taciti sociosqu ad
          litora torquent per conubia nostra, per inceptos himenaeos. Donec
          pretium leo et nulla malesuada ultrices. Praesent consectetur neque eu
          pharetra luctus. Pellentesque porta nulla id nibh tincidunt
          ullamcorper. Cras non fermentum velit. In leo ex, sollicitudin
          convallis sodales non, dapibus id nibh. Fusce nisl massa, porta ut
          dignissim non, tempor sit amet erat.
        </Atom.Paragraph>
      </Atom.Container>
    </Atom.Box>
  );
};
