"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "firebase", {
  enumerable: true,
  get: function get() {
    return _firebase.default;
  }
});
Object.defineProperty(exports, "FirebaseContext", {
  enumerable: true,
  get: function get() {
    return _Context.default;
  }
});
Object.defineProperty(exports, "withFirebase", {
  enumerable: true,
  get: function get() {
    return _withContext.default;
  }
});
Object.defineProperty(exports, "FirebaseProvider", {
  enumerable: true,
  get: function get() {
    return _Provider.default;
  }
});
Object.defineProperty(exports, "FirebaseInject", {
  enumerable: true,
  get: function get() {
    return _Inject.default;
  }
});
exports.selectors = exports.hooks = exports.helpers = void 0;

var helpers = _interopRequireWildcard(require("./helpers"));

exports.helpers = helpers;

var hooks = _interopRequireWildcard(require("./hooks"));

exports.hooks = hooks;

var selectors = _interopRequireWildcard(require("./selectors"));

exports.selectors = selectors;

var _firebase = _interopRequireDefault(require("firebase"));

var _Context = _interopRequireDefault(require("./Context"));

var _withContext = _interopRequireDefault(require("./withContext"));

var _Provider = _interopRequireDefault(require("./components/Provider"));

var _Inject = _interopRequireDefault(require("./components/Inject"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }