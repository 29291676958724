module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"EthQuest","short_name":"EthQuest","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","path":"/Users/admin/Documents/GitHub/RAPID/prototypes/ethquest/app/src/assets/images/favicon.png"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
