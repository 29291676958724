"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.contractDeployRequest = void 0;

var _types = require("../types");

var contractDeployRequest = (state, dispatch) => (_ref) => {
  var {
    contract,
    inputs
  } = _ref;
  return dispatch({
    type: _types.CONTRACT_DEPLOY_REQUEST,
    payload: {
      contract,
      inputs
    }
  });
};

exports.contractDeployRequest = contractDeployRequest;