"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useWalletProviderInitialize = void 0;

var _react = require("react");

var _types = require("../types");

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

/* --- Effect --- */
var useWalletProviderInitialize = (state, dispatch) => {
  var [providerInitialize, setProviderInitialize] = (0, _react.useState)(false);
  (0, _react.useEffect)(() => {
    if (state.address && !providerInitialize) {
      _asyncToGenerator(function* () {
        try {
          var provider = yield new state.instance.providers.Web3Provider(window.web3.currentProvider);
          dispatch({
            type: _types.PROVIDER_SET,
            payload: provider
          });
          setProviderInitialize(true);
        } catch (error) {
          dispatch({
            type: _types.PROVIDER_SET_STATUS,
            payload: undefined
          });
        }
      })();
    }
  }, [state.address, providerInitialize]);
  return true;
};

exports.useWalletProviderInitialize = useWalletProviderInitialize;