"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useBalanceChange = void 0;

var _react = require("react");

var _system = require("@ethers-react/system");

var _ethers = require("ethers");

/* --- Global --- */

/* --- Effect --- */
var useBalanceChange = (provider, adrs) => {
  var ethers = (0, _system.withEthers)();
  var [address, setAddress] = (0, _react.useState)(adrs);
  var [balance, setBalance] = (0, _react.useState)({});
  /* --- Block Mind : Listen Event --- */

  (0, _react.useEffect)(() => {
    if (address && ethers.provider) {
      console.log("running effect");
      ethers.provider.on(address, balanceBigNumber => setBalance({
        number: balanceBigNumber,
        wei: balanceBigNumber.toString(),
        eth: ethers.instance.utils.formatEther(balanceBigNumber),
        trimmed: ethers.instance.utils.formatEther(balanceBigNumber).substring(0, 5)
      }));
    }

    return () => {
      if (ethers.provider) {} // ethers.provider.removeListener(address, removed => {
      //   console.log(removed);
      // });
      // setBalance(undefined);

    };
  }, [ethers.provider]);
  return {
    address,
    balance,
    setAddress
  };
};

exports.useBalanceChange = useBalanceChange;