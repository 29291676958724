"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _ethers = require("ethers");

var _fortmatic = _interopRequireDefault(require("fortmatic"));

var _Context = _interopRequireDefault(require("../Context"));

var _reducer = _interopRequireDefault(require("../reducer"));

var _types = require("../types");

var actionsList = _interopRequireWildcard(require("../actions"));

var _middleware = require("../middleware");

var _utility = require("../utility");

var _system = require("../system");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

/* --- Component --- */
var Provider = (_ref) => {
  var {
    children,
    contracts,
    extensions
  } = _ref;
  var [, setFortmatic] = (0, _react.useState)();
  /* --- Ethers Context --- */

  var initialState = (0, _react.useContext)(_Context.default);
  /* --- Reducer --- */

  var [state, dispatch] = (0, _react.useReducer)((0, _utility.combineExtensionsReducers)([{
    name: 'core',
    reducer: _reducer.default
  }, ...extensions]), (0, _utility.combineExtensionInitialState)([{
    name: 'core',
    initialState
  }, ...extensions]), (0, _middleware.contractLoad)(contracts));
  /* --- System --- */

  (0, _system.useContractConnect)(state, dispatch);
  (0, _system.useWalletEnable)(state);
  (0, _react.useEffect)(() => {
    _asyncToGenerator(function* () {
      var fortmatic = yield new _fortmatic.default('pk_test_FCFB0E09CC518A99');
      var provider = yield new _ethers.ethers.providers.Web3Provider(fortmatic.getProvider());
      dispatch({
        type: _types.PROVIDER_SET,
        payload: provider
      });
    })();
  }, [dispatch]);
  /* --- Extensions : Initialize --- */

  (0, _middleware.extensionsInitialize)(extensions, state, dispatch);
  /* --- Enhance Actions --- */

  var actions = (0, _middleware.enhanceActions)(extensions, dispatch);
  var actionList = (0, _middleware.enhanceSetActions)(actionsList, state, dispatch);
  console.log(actionList, 'actionList');
  /* --- Developer Messages --- */

  console.log(state, 'Ethers Provider');
  return _react.default.createElement(_Context.default.Provider, {
    value: _objectSpread({}, state, {}, actions, {}, actionList)
  }, children);
};

var _default = Provider;
exports.default = _default;