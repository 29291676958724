"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = require("react");

/**
 * @name FirebaseContext
 */
var Context = (0, _react.createContext)({
  scope: undefined,
  user: undefined
});
var _default = Context;
exports.default = _default;