"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.walletSignMessageRequest = void 0;

var _types = require("../types");

var walletSignMessageRequest = (state, dispatch) => (_ref) => {
  var {
    message,
    delta
  } = _ref;
  return dispatch({
    type: _types.WALLET_SIGN_MESSAGE_REQUEST,
    payload: message
  });
};

exports.walletSignMessageRequest = walletSignMessageRequest;