"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addressSet = void 0;

var _types = require("../types");

var addressSet = (state, dispatch) => address => dispatch({
  type: _types.SET_ADDRESS,
  payload: address
});

exports.addressSet = addressSet;