"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.enhanceSetActions = exports.enhanceActions = void 0;

/**
 * @function enhanceActions
 * @description Wrap extenion actions with dispatch
 */
var enhanceActions = (extensions, dispatch) => {
  var actions = {};
  extensions.map(extension => {
    if (extension.actions) {
      return Object.keys(extension.actions).forEach(action => {
        actions[action] = extension.actions[action](dispatch);
      });
    }
  });
  return actions;
};
/**
 * @function enhanceSetActions
 * @description Wrap extenion actions with dispatch
 */


exports.enhanceActions = enhanceActions;

var enhanceSetActions = (list, state, dispatch) => {
  var actions = {};
  Object.keys(list).forEach(action => {
    actions[action] = list[action](state, dispatch);
  });
  return actions;
};

exports.enhanceSetActions = enhanceSetActions;