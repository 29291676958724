/* --- Global --- */
import './assets/reset.css';
import './assets/App.css';
// import {useState, useEffect} from 'react';
import {EthersProvider} from '@ethers-react/system';
import {extension as Reactive} from '@ethers-react/reactive';
import {extension as Global} from '@ethers-react/global';
import {ThemeProvider} from 'theme-ui';
import {FirebaseProvider} from '@horizin/firebase-system';
// import awsconfig from './aws-exports';
import Fortmatic from 'fortmatic';

/* --- Local --- */
import theme from './assets/theme';

// Reactive.initialState.reactive.getAccountBalance = true;
// Reactive.initialState.reactive.getAccountNonce = true;
// Reactive.initialState.reactive.getAccountOnLoad = true;
Reactive.initialState.reactive.getProviderSigner = true;
// Reactive.initialState.reactive.watchAccountOnChange = true;
// Reactive.initialState.reactive.watchBlockCurrent = true;

export default ({element}) => {
  // const [fortmatmicProvider, setFortmatic] = useState();

  // useEffect(() => {
  //   let fm = new Fortmatic('pk_test_FCFB0E09CC518A99');
  //   setFortmatic(fm);
  // }, []);
  return (
    <ThemeProvider theme={theme}>
      <FirebaseProvider>
        <EthersProvider extensions={[Reactive]}>{element}</EthersProvider>
      </FirebaseProvider>
    </ThemeProvider>
  );
};
