"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TRANSACTION_REJECTED = exports.TRANSACTION_COMPLETE = exports.TRANSACTION_SUBMITTED = void 0;
var TRANSACTION_SUBMITTED = 'TRANSACTION_SUBMITTED';
exports.TRANSACTION_SUBMITTED = TRANSACTION_SUBMITTED;
var TRANSACTION_COMPLETE = 'TRANSACTION_COMPLETE';
exports.TRANSACTION_COMPLETE = TRANSACTION_COMPLETE;
var TRANSACTION_REJECTED = 'TRANSACTION_REJECTED';
exports.TRANSACTION_REJECTED = TRANSACTION_REJECTED;