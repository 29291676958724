"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useBlockMined = void 0;

var _react = require("react");

var _system = require("@ethers-react/system");

/**
 * @function useAddressSet
 * @param {Object} state
 * @param {Object} dispatch
 */

/* --- Global --- */

/* --- useBlockMined : Effect --- */
var useBlockMined = provider => {
  var ethers = (0, _system.withEthers)();
  var [isReady, setIsReady] = (0, _react.useState)();
  var [block, setBlock] = (0, _react.useState)(0);
  (0, _react.useEffect)(() => {
    if (ethers.provider) {
      ethers.provider.getBlockNumber().then(blockNumber => {
        setBlock(blockNumber);
        setIsReady(true);
      });
      var cnt = ethers.provider.listenerCount("block");
    }
  }, []);
  (0, _react.useEffect)(() => {
    if (ethers.provider) {
      ethers.provider.getBlockNumber().then(blockNumber => {
        setBlock(blockNumber);
        setIsReady(true);
      });
    }
  }, [ethers.provider]);
  /* --- Block Mind : Listen Event --- */

  (0, _react.useEffect)(() => {
    if (ethers.provider) {
      ethers.provider.on("block", block => setBlock(block));
    }

    return () => {
      if (ethers.provider) {
        ethers.provider.removeAllListeners("block");
      }
    };
  }, [isReady]);
  return block;
};

exports.useBlockMined = useBlockMined;