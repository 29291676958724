/**
 * @name RegionFooter
 * @description Default footer region for application.
 */
/* --- Global --- */
import {Link} from '@reach/router';

/* --- Local --- */
import headshot from '@assets/images/headshot.jpg';
import wave from '@assets/images/bump.png';
/* --- Component --- */
export default props => {
  return (
    <Atom.Box>
      <Atom.Flex
        alignCenter
        between
        column
        gradient="bluePurple"
        direction={180}
        sx={{variant: 'regions.footer'}}>
        {/* Left */}
        <Atom.Flex center column sx={{color: 'white'}}>
          <Atom.Image
            sx={{
              bg: 'white',
              borderRadius: 9999,
              border: '1px solid #FFF',
              boxShadow: 2,
              my: 3,
              transition: '0.2s all',
              p: 2,
              top: 0,
              width: 55,
              '&:hover': {
                boxShadow: 4,
                top: -1,
              },
            }}
            src="https://image.flaticon.com/icons/svg/1673/1673614.svg"
          />
          <Atom.Box sx={{textAlign: 'center'}}>
            <Link to="/">
              <Atom.Heading xl heavy mb={3}>
                {GLOBAL.siteName}
              </Atom.Heading>
            </Link>
          </Atom.Box>
        </Atom.Flex>

        {/* Right */}

        <Atom.Span sx={{fontSize: 1}}>Adventure of a Lifetime</Atom.Span>
        {/* <Atom.Flex sx={{mt: 2}}>
          <Molecule.Link to="/bounties" sx={{mr: 3}}>
            <Atom.Span tag sm>
              Bounties
            </Atom.Span>
          </Molecule.Link>
          <Molecule.Link to="/quests" sx={{ml: 2}}>
            <Atom.Span tag sm>
              Quests
            </Atom.Span>
          </Molecule.Link>
        </Atom.Flex> */}
      </Atom.Flex>
      <WaveTop />
    </Atom.Box>
  );
};

const WaveTop = props => {
  return (
    <Atom.Absolute sx={{top: -1, left: 0, right: 0, zIndex: 1000}}>
      <Atom.Image
        src={wave}
        sx={{
          transform: 'rotate(180deg)',
          height: 50,
          width: '100%',
        }}
      />
    </Atom.Absolute>
  );
};
