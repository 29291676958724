"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.USER_SET = exports.ENABLE_FAILURE = exports.ENABLE_SUCCESS = exports.ENABLE_REQUEST = void 0;
// * Set Actions
var ENABLE_REQUEST = 'ENABLE_REQUEST';
exports.ENABLE_REQUEST = ENABLE_REQUEST;
var ENABLE_SUCCESS = 'ENABLE_SUCCESS';
exports.ENABLE_SUCCESS = ENABLE_SUCCESS;
var ENABLE_FAILURE = 'ENABLE_FAILURE';
exports.ENABLE_FAILURE = ENABLE_FAILURE;
var USER_SET = 'USER_SET';
exports.USER_SET = USER_SET;