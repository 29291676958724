"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useWalletSignMessage = void 0;

var _react = require("react");

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

/* --- Effect --- */
var useWalletSignMessage = props => {
  var [messageUnsigned, setMessageUnsigned] = (0, _react.useState)(undefined);
  var [messageSigned, setMessageSigned] = (0, _react.useState)(undefined);
  var [wallet, setWallet] = (0, _react.useState)(undefined);
  /* --- Error : State --- */

  var [messageSignedError, setMessageSignedError] = (0, _react.useState)(undefined);
  /* --- Initialize --- */

  var init = (wallet, msg) => {
    setMessageUnsigned(msg);
    setWallet(wallet);
  };
  /* --- Reset --- */


  var reset = (wallet, msg) => {
    setMessageUnsigned(undefined);
    setMessageSigned(undefined);
    setWallet(undefined);
  };
  /* --- Sign Message :: Effect --- */


  (0, _react.useEffect)(() => {
    if (messageUnsigned && !messageSigned && wallet) {
      _asyncToGenerator(function* () {
        try {
          var _messageSigned = yield wallet.signMessage(messageUnsigned);

          setMessageSigned(_messageSigned);
        } catch (error) {
          setMessageSignedError(error);
        }
      })();
    }
  }, [messageUnsigned, wallet]);
  /* --- Return : Complete --- */

  return {
    init,
    reset,
    messageSigned,
    messageUnsigned,
    error: messageSignedError
  };
};

exports.useWalletSignMessage = useWalletSignMessage;