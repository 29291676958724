"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TRANSACTION_REJECTED = exports.TRANSACTION_COMPLETE = exports.TRANSACTION_SUBMITTED = exports.CONTRACT_DEPLOY_REJECTED = exports.CONTRACT_DEPLOY_COMPLETE = exports.CONTRACT_DEPLOY_SUBMITTED = void 0;
var CONTRACT_DEPLOY_SUBMITTED = 'CONTRACT_DEPLOY_SUBMITTED';
exports.CONTRACT_DEPLOY_SUBMITTED = CONTRACT_DEPLOY_SUBMITTED;
var CONTRACT_DEPLOY_COMPLETE = 'CONTRACT_DEPLOY_COMPLETE';
exports.CONTRACT_DEPLOY_COMPLETE = CONTRACT_DEPLOY_COMPLETE;
var CONTRACT_DEPLOY_REJECTED = 'CONTRACT_DEPLOY_REJECTED';
exports.CONTRACT_DEPLOY_REJECTED = CONTRACT_DEPLOY_REJECTED;
var TRANSACTION_SUBMITTED = 'TRANSACTION_SUBMITTED';
exports.TRANSACTION_SUBMITTED = TRANSACTION_SUBMITTED;
var TRANSACTION_COMPLETE = 'TRANSACTION_COMPLETE';
exports.TRANSACTION_COMPLETE = TRANSACTION_COMPLETE;
var TRANSACTION_REJECTED = 'TRANSACTION_REJECTED';
exports.TRANSACTION_REJECTED = TRANSACTION_REJECTED;