/**
 * @name RegionTop
 * @description Default top region for application.
 */
/* --- Global --- */
import {Link} from 'gatsby';

/* --- Local --- */
import {ColorMode} from '@components/common';
export default props => (
  <Atom.Flex alignCenter between sx={{variant: 'regions.header', py: 3}}>
    {/* Left */}
    <Atom.Flex alignCenter sx={{flex: 1, width: '100%'}}>
      <Atom.Flex sx={{flex: 1}}></Atom.Flex>

      {/* Menu */}
      <Atom.Flex center sx={{flex: 1}}></Atom.Flex>

      {/* Right */}
      <Atom.Flex center sx={{flex: 1, justifyContent: 'flex-end'}}>
        {/* <LanguageSelection /> */}
        <Atom.Box sx={{mx: 3}}>{/* <ColorMode /> */}</Atom.Box>
      </Atom.Flex>
    </Atom.Flex>
  </Atom.Flex>
);

const LanguageSelection = props => {
  return (
    <div>
      <Link to="/" hrefLang="en">
        English
      </Link>
      {` `}/{` `}
      <Link to="/de" hrefLang="de">
        Deutsch
      </Link>
    </div>
  );
};
