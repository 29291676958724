"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.combineReducers = void 0;

var combineReducers = reducers => {
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments.length > 1 ? arguments[1] : undefined;
    return Object.keys(reducers).reduce((nextState, key) => {
      nextState[key] = reducers[key](state[key], action);
      return nextState;
    }, {});
  };
};

exports.combineReducers = combineReducers;