"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useGetTransactionReceipt = void 0;

var _react = require("react");

var _system = require("@ethers-react/system");

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

/* --- Effect --- */
var useGetTransactionReceipt = props => {
  var ethers = (0, _system.withEthers)();
  var [transactionHash, setTransactionHash] = (0, _react.useState)();
  var [transactionReceipt, setTransactionReceipt] = (0, _react.useState)();
  var [transactionReceiptError, setTransactionReceiptError] = (0, _react.useState)();
  /* --- Error : State --- */

  var [error, setError] = (0, _react.useState)();
  /* --- Initialize --- */

  var init = txHash => {
    setTransactionHash(txHash);
  };
  /* --- Sign Message :: Effect --- */


  (0, _react.useEffect)(() => {
    if (true) {
      _asyncToGenerator(function* () {
        try {
          var txReceipt = yield ethers.provider.getTransactionReceipt(transactionHash);
          console.log(txReceipt);
          setTransactionData(txReceipt);
        } catch (error) {
          setTransactionReceiptError(error);
        }
      })();
    }
  }, []);
  /* --- Return : Complete --- */

  return {
    init,
    transactionHash,
    transactionReceipt,
    error: transactionReceiptError
  };
};

exports.useGetTransactionReceipt = useGetTransactionReceipt;