"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.contractLoad = void 0;

var _helpers = require("../helpers");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* --- Configuration --- */
var DEPLOYED = "deployed";
var FACTORY = "factory";
/* --- Function --- */

var contractLoad = contracts => initialState => {
  var contractLibrary = {};

  if (Array.isArray(contracts)) {
    contracts.forEach(contract => {
      /* --- Standard JSON : Smart Contract JSON --- */
      if (contract.networks) {
        var networkId = Object.keys(contract.networks)[0];
        var address = contract.networks[networkId].address;
        var transactionHash = contract.networks[networkId].transactionHash;
        contractLibrary[address] = {
          address: address,
          abi: contract.abi,
          bytecode: contract.bytecode,
          transactionHash: transactionHash,
          name: contract.name,
          type: DEPLOYED,
          network: {
            chainId: Number(networkId),
            name: (0, _helpers.selectNetworkName)(networkId)
          }
        };
      }
      /* --- Custom Input : Smart Contract --- */


      if (contract.network) {
        contractLibrary[contract.address] = {
          address: contract.address,
          abi: contract.abi,
          bytecode: contract.bytecode,
          contractName: contract.name,
          transactionHash: contract.transactionHash,
          name: contract.name,
          type: DEPLOYED,
          network: contract.network
        };
      }
      /* --- Factory : Smart Contract --- */


      if (!contract.network && !contract.address && contract.id) {
        contractLibrary[contract.id] = {
          abi: contract.abi,
          bytecode: contract.bytecode,
          type: FACTORY
        };
      }
    });
    return _objectSpread({}, initialState, {
      contracts: _objectSpread({}, contractLibrary)
    });
  } else {
    return initialState;
  }
};

exports.contractLoad = contractLoad;