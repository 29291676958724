"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CONTRACT_DEPLOY_MONITOR_FAILURE = exports.CONTRACT_DEPLOY_MONITOR_SUCCESS = exports.CONTRACT_DEPLOY_MONITOR_REQUEST = exports.TRANSACTION_MONITOR_FAILURE = exports.TRANSACTION_MONITOR_SUCCESS = exports.TRANSACTION_MONITOR_REQUEST = exports.SET_ADDRESS = exports.SET_WALLET_FAILURE = exports.SET_WALLET_SUCCESS = exports.SET_WALLET_REQUEST = exports.SET_WALLET = exports.WALLET_SEND_TRANSACTION_FAILURE = exports.WALLET_SEND_TRANSACTION_SUCCESS = exports.WALLET_SEND_TRANSACTION_REQUEST = exports.WALLET_SIGN_TRANSACTION_FAILURE = exports.WALLET_SIGN_TRANSACTION_SUCCESS = exports.WALLET_SIGN_TRANSACTION_REQUEST = exports.WALLET_SIGN_MESSAGE_FAILURE = exports.WALLET_SIGN_MESSAGE_SUCCESS = exports.WALLET_SIGN_MESSAGE_REQUEST = exports.WALLET_SIGN_TYPED_MESSAGE_FAILURE = exports.WALLET_SIGN_TYPED_MESSAGE_SUCCESS = exports.WALLET_SIGN_TYPED_MESSAGE_REQUEST = exports.WALLET_PROVIDER_GET_FAILURE = exports.WALLET_PROVIDER_GET_SUCCESS = exports.WALLET_PROVIDER_GET_REQUEST = exports.SIGNER_GET_FAILURE = exports.SIGNER_GET_SUCCESS = exports.SIGNER_GET_REQUEST = exports.CONTRACT_DEPLOY_FROM_BYTECODE_FAILURE = exports.CONTRACT_DEPLOY_FROM_BYTECODE_SUCCESS = exports.CONTRACT_DEPLOY_FROM_BYTECODE_REQUEST = exports.CONTRACT_DEPLOY_FAILURE = exports.CONTRACT_DEPLOY_SUCCESS = exports.CONTRACT_DEPLOY_REQUEST = exports.CONTRACT_INITIALIZE_FAILURE = exports.CONTRACT_INITIALIZE_SUCCESS = exports.CONTRACT_INITIALIZE_REQUEST = exports.NONCE_SET = exports.NETWORK_SET = exports.ENS_ADDRESS_SET = exports.BALANCE_SET = exports.INIT_CONTRACT_FAILURE = exports.INIT_CONTRACT_SUCCESS = exports.INIT_CONTRACT_REQUEST = exports.ENABLE_FAILURE = exports.ENABLE_SUCCESS = exports.ENABLE_REQUEST = exports.PROVIDER_SET_STATUS = exports.PROVIDER_SET_MULTIPLE = exports.PROVIDER_SET = void 0;
// * Set Actions
var PROVIDER_SET = "PROVIDER_SET";
exports.PROVIDER_SET = PROVIDER_SET;
var PROVIDER_SET_MULTIPLE = "PROVIDER_SET_MULTIPLE";
exports.PROVIDER_SET_MULTIPLE = PROVIDER_SET_MULTIPLE;
var PROVIDER_SET_STATUS = "PROVIDER_SET_STATUS";
exports.PROVIDER_SET_STATUS = PROVIDER_SET_STATUS;
var ENABLE_REQUEST = "ENABLE_REQUEST";
exports.ENABLE_REQUEST = ENABLE_REQUEST;
var ENABLE_SUCCESS = "ENABLE_SUCCESS";
exports.ENABLE_SUCCESS = ENABLE_SUCCESS;
var ENABLE_FAILURE = "ENABLE_FAILURE";
exports.ENABLE_FAILURE = ENABLE_FAILURE;
var INIT_CONTRACT_REQUEST = "INIT_CONTRACT_REQUEST";
exports.INIT_CONTRACT_REQUEST = INIT_CONTRACT_REQUEST;
var INIT_CONTRACT_SUCCESS = "INIT_CONTRACT_SUCCESS";
exports.INIT_CONTRACT_SUCCESS = INIT_CONTRACT_SUCCESS;
var INIT_CONTRACT_FAILURE = "INIT_CONTRACT_FAILURE";
exports.INIT_CONTRACT_FAILURE = INIT_CONTRACT_FAILURE;
var BALANCE_SET = "BALANCE_SET";
exports.BALANCE_SET = BALANCE_SET;
var ENS_ADDRESS_SET = "ENS_ADDRESS_SET";
exports.ENS_ADDRESS_SET = ENS_ADDRESS_SET;
var NETWORK_SET = "NETWORK_SET";
exports.NETWORK_SET = NETWORK_SET;
var NONCE_SET = "NONCE_SET";
/* ----------------------- */

/*         Contact         */

/* ----------------------- */

exports.NONCE_SET = NONCE_SET;
var CONTRACT_INITIALIZE_REQUEST = "CONTRACT_INITIALIZE_REQUEST";
exports.CONTRACT_INITIALIZE_REQUEST = CONTRACT_INITIALIZE_REQUEST;
var CONTRACT_INITIALIZE_SUCCESS = "CONTRACT_INITIALIZE_SUCCESS";
exports.CONTRACT_INITIALIZE_SUCCESS = CONTRACT_INITIALIZE_SUCCESS;
var CONTRACT_INITIALIZE_FAILURE = "CONTRACT_INITIALIZE_FAILURE";
exports.CONTRACT_INITIALIZE_FAILURE = CONTRACT_INITIALIZE_FAILURE;
var CONTRACT_DEPLOY_REQUEST = "CONTRACT_DEPLOY_REQUEST";
exports.CONTRACT_DEPLOY_REQUEST = CONTRACT_DEPLOY_REQUEST;
var CONTRACT_DEPLOY_SUCCESS = "CONTRACT_DEPLOY_SUCCESS";
exports.CONTRACT_DEPLOY_SUCCESS = CONTRACT_DEPLOY_SUCCESS;
var CONTRACT_DEPLOY_FAILURE = "CONTRACT_DEPLOY_FAILURE";
exports.CONTRACT_DEPLOY_FAILURE = CONTRACT_DEPLOY_FAILURE;
var CONTRACT_DEPLOY_FROM_BYTECODE_REQUEST = "CONTRACT_DEPLOY_FROM_BYTECODE_REQUEST";
exports.CONTRACT_DEPLOY_FROM_BYTECODE_REQUEST = CONTRACT_DEPLOY_FROM_BYTECODE_REQUEST;
var CONTRACT_DEPLOY_FROM_BYTECODE_SUCCESS = "CONTRACT_DEPLOY_FROM_BYTECODE_SUCCESS";
exports.CONTRACT_DEPLOY_FROM_BYTECODE_SUCCESS = CONTRACT_DEPLOY_FROM_BYTECODE_SUCCESS;
var CONTRACT_DEPLOY_FROM_BYTECODE_FAILURE = "CONTRACT_DEPLOY_FROM_BYTECODE_FAILURE";
/* ----------------------- */

/*         Wallet         */

/* ----------------------- */

exports.CONTRACT_DEPLOY_FROM_BYTECODE_FAILURE = CONTRACT_DEPLOY_FROM_BYTECODE_FAILURE;
var SIGNER_GET_REQUEST = "SIGNER_GET_REQUEST";
exports.SIGNER_GET_REQUEST = SIGNER_GET_REQUEST;
var SIGNER_GET_SUCCESS = "SIGNER_GET_SUCCESS";
exports.SIGNER_GET_SUCCESS = SIGNER_GET_SUCCESS;
var SIGNER_GET_FAILURE = "SIGNER_GET_FAILURE";
exports.SIGNER_GET_FAILURE = SIGNER_GET_FAILURE;
var WALLET_PROVIDER_GET_REQUEST = "WALLET_PROVIDER_GET_REQUEST";
exports.WALLET_PROVIDER_GET_REQUEST = WALLET_PROVIDER_GET_REQUEST;
var WALLET_PROVIDER_GET_SUCCESS = "WALLET_PROVIDER_GET_SUCCESS";
exports.WALLET_PROVIDER_GET_SUCCESS = WALLET_PROVIDER_GET_SUCCESS;
var WALLET_PROVIDER_GET_FAILURE = "WALLET_PROVIDER_GET_FAILURE";
exports.WALLET_PROVIDER_GET_FAILURE = WALLET_PROVIDER_GET_FAILURE;
var WALLET_SIGN_TYPED_MESSAGE_REQUEST = "WALLET_SIGN_TYPED_MESSAGE_REQUEST";
exports.WALLET_SIGN_TYPED_MESSAGE_REQUEST = WALLET_SIGN_TYPED_MESSAGE_REQUEST;
var WALLET_SIGN_TYPED_MESSAGE_SUCCESS = "WALLET_SIGN_TYPED_MESSAGE_SUCCESS";
exports.WALLET_SIGN_TYPED_MESSAGE_SUCCESS = WALLET_SIGN_TYPED_MESSAGE_SUCCESS;
var WALLET_SIGN_TYPED_MESSAGE_FAILURE = "WALLET_SIGN_TYPED_MESSAGE_FAILURE";
exports.WALLET_SIGN_TYPED_MESSAGE_FAILURE = WALLET_SIGN_TYPED_MESSAGE_FAILURE;
var WALLET_SIGN_MESSAGE_REQUEST = "SIGN_MESSAGE";
exports.WALLET_SIGN_MESSAGE_REQUEST = WALLET_SIGN_MESSAGE_REQUEST;
var WALLET_SIGN_MESSAGE_SUCCESS = "WALLET_SIGN_MESSAGE_SUCCESS";
exports.WALLET_SIGN_MESSAGE_SUCCESS = WALLET_SIGN_MESSAGE_SUCCESS;
var WALLET_SIGN_MESSAGE_FAILURE = "WALLET_SIGN_MESSAGE_FAILURE";
exports.WALLET_SIGN_MESSAGE_FAILURE = WALLET_SIGN_MESSAGE_FAILURE;
var WALLET_SIGN_TRANSACTION_REQUEST = "WALLET_SIGN_TRANSACTION_REQUEST";
exports.WALLET_SIGN_TRANSACTION_REQUEST = WALLET_SIGN_TRANSACTION_REQUEST;
var WALLET_SIGN_TRANSACTION_SUCCESS = "WALLET_SIGN_TRANSACTION_SUCCESS";
exports.WALLET_SIGN_TRANSACTION_SUCCESS = WALLET_SIGN_TRANSACTION_SUCCESS;
var WALLET_SIGN_TRANSACTION_FAILURE = "WALLET_SIGN_TRANSACTION_FAILURE";
exports.WALLET_SIGN_TRANSACTION_FAILURE = WALLET_SIGN_TRANSACTION_FAILURE;
var WALLET_SEND_TRANSACTION_REQUEST = "WALLET_SEND_TRANSACTION_REQUEST";
exports.WALLET_SEND_TRANSACTION_REQUEST = WALLET_SEND_TRANSACTION_REQUEST;
var WALLET_SEND_TRANSACTION_SUCCESS = "WALLET_SEND_TRANSACTION_SUCCESS";
exports.WALLET_SEND_TRANSACTION_SUCCESS = WALLET_SEND_TRANSACTION_SUCCESS;
var WALLET_SEND_TRANSACTION_FAILURE = "WALLET_SEND_TRANSACTION_FAILURE";
exports.WALLET_SEND_TRANSACTION_FAILURE = WALLET_SEND_TRANSACTION_FAILURE;
var SET_WALLET = "SET_WALLET";
exports.SET_WALLET = SET_WALLET;
var SET_WALLET_REQUEST = "SET_WALLET_REQUEST";
exports.SET_WALLET_REQUEST = SET_WALLET_REQUEST;
var SET_WALLET_SUCCESS = "SET_WALLET_SUCCESS";
exports.SET_WALLET_SUCCESS = SET_WALLET_SUCCESS;
var SET_WALLET_FAILURE = "SET_WALLET_FAILURE";
exports.SET_WALLET_FAILURE = SET_WALLET_FAILURE;
var SET_ADDRESS = "SET_ADDRESS";
exports.SET_ADDRESS = SET_ADDRESS;
var TRANSACTION_MONITOR_REQUEST = "TRANSACTION_MONITOR_REQUEST";
exports.TRANSACTION_MONITOR_REQUEST = TRANSACTION_MONITOR_REQUEST;
var TRANSACTION_MONITOR_SUCCESS = "TRANSACTION_MONITOR_SUCCESS";
exports.TRANSACTION_MONITOR_SUCCESS = TRANSACTION_MONITOR_SUCCESS;
var TRANSACTION_MONITOR_FAILURE = "TRANSACTION_MONITOR_FAILURE";
exports.TRANSACTION_MONITOR_FAILURE = TRANSACTION_MONITOR_FAILURE;
var CONTRACT_DEPLOY_MONITOR_REQUEST = "CONTRACT_DEPLOY_MONITOR_REQUEST";
exports.CONTRACT_DEPLOY_MONITOR_REQUEST = CONTRACT_DEPLOY_MONITOR_REQUEST;
var CONTRACT_DEPLOY_MONITOR_SUCCESS = "CONTRACT_DEPLOY_MONITOR_SUCCESS";
exports.CONTRACT_DEPLOY_MONITOR_SUCCESS = CONTRACT_DEPLOY_MONITOR_SUCCESS;
var CONTRACT_DEPLOY_MONITOR_FAILURE = "CONTRACT_DEPLOY_MONITOR_FAILURE";
exports.CONTRACT_DEPLOY_MONITOR_FAILURE = CONTRACT_DEPLOY_MONITOR_FAILURE;