/* --- Global --- */
import React, {useState, useEffect} from 'react';
import {Router} from '@reach/router';
/* --- Local --- */
import wave from '@assets/images/bump.png';

import questListDevQuest from '@data/questListMillionDevs';
import questListConsenQuest from '@data/questListConsenQuest';
/* --- Module --- */
import QuestUnavailable from './QuestUnavailable';

/* --- Template --- */
export default function PageTemplate(props) {
  return (
    <>
      <Router primary={false}>
        <ConsenquestList path="/quest/consenquest/:id" />
        <DevquestList path="/quest/devquest/:id" />
      </Router>
    </>
  );
}

const ConsenquestList = props => {
  const [isFound, setIsFound] = useState(false);
  const [quest, setQuest] = useState();
  useEffect(() => {
    const questMatchId = questListConsenQuest.filter(
      quest => quest.id === props.id,
    );
    if (questMatchId.length > 0) {
      setQuest(questMatchId[0]);
      setIsFound(true);
    } else {
      setIsFound(false);
    }
  }, [props.id]);

  return !isFound ? (
    <QuestUnavailable />
  ) : (
    <QuestItem id={props.id} quest={quest} />
  );
};

const DevquestList = props => {
  const [isFound, setIsFound] = useState(false);
  const [quest, setQuest] = useState();
  useEffect(() => {
    const questMatchId = questListDevQuest.filter(
      quest => quest.id === props.id,
    );
    if (questMatchId.length > 0) {
      setQuest(questMatchId[0]);
      setIsFound(true);
    } else {
      setIsFound(false);
    }
  }, [props.id]);

  return !isFound ? (
    <QuestUnavailable />
  ) : (
    <QuestItem id={props.id} quest={quest} />
  );
};

/* --- Quest : Component --- */
const QuestItem = props => {
  return (
    <Atom.Flex column>
      <QuestShowcase id={props.id} quest={props.quest} />
      <QuestContent id={props.id} quest={props.quest} />
    </Atom.Flex>
  );
};

const QuestShowcase = props => {
  return (
    <Atom.Flex
      center
      column
      sx={{
        color: 'white',
        pt: 6,
        pb: 6,
        width: '100%',
        zIndex: 1000,
      }}>
      <Atom.BackgroundGradient gradient="bluePurple" direction={0} />
      <Atom.Absolute sx={{bottom: 0, left: 0, right: 0}}>
        <Atom.Image src={wave} sx={{height: 80, width: '100%'}} />
      </Atom.Absolute>
      <Atom.Container
        sx={{
          pt: 2,
          pb: 4,
          maxWidth: ['100%', '100%', 620, 780],
        }}>
        <Atom.Box sx={{flex: 2, textAlign: 'center'}}>
          <Atom.Span card circle>
            <Atom.Image sx={{width: 53}} src={props.quest.image} />
          </Atom.Span>
          <Atom.Heading
            sx={{
              fontSize: [5, 5, 5, 6],
            }}>
            {props.quest.title}
          </Atom.Heading>
          <Atom.Heading
            sx={{
              fontSize: [3, 3, 3, 4],
            }}>
            {props.quest.subtitle}
          </Atom.Heading>
        </Atom.Box>
      </Atom.Container>
    </Atom.Flex>
  );
};

const QuestContent = props => {
  return (
    <Atom.Flex between column flex={1} minHeight="100vh">
      <Atom.Box
        as="main"
        sx={{
          flex: 1,
          py: 2,
        }}>
        <Atom.Container
          sx={{
            maxWidth: ['100%', '100%', 560, 620],
          }}>
          <Atom.Flex center column>
            <Atom.Heading as="h5" normal giga sx={{textAlign: 'center'}}>
              {props.quest.reward.amount} {props.quest.reward.token.symbol}
              <br />
              <Atom.Span md>
                <strong>Reward</strong>
              </Atom.Span>
            </Atom.Heading>
          </Atom.Flex>
          <Atom.HorizontalRule
            sx={{
              my: 3,
              mx: 'auto',
              width: 50,
            }}
          />
          <Atom.Paragraph>{props.quest.body}</Atom.Paragraph>
        </Atom.Container>
      </Atom.Box>
    </Atom.Flex>
  );
};
