"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "useContractConnect", {
  enumerable: true,
  get: function get() {
    return _useContractConnect.useContractConnect;
  }
});
Object.defineProperty(exports, "useWalletEnable", {
  enumerable: true,
  get: function get() {
    return _useWalletEnable.useWalletEnable;
  }
});

var _useContractConnect = require("./useContractConnect");

var _useWalletEnable = require("./useWalletEnable");