"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/GitHub/RAPID/prototypes/ethquest/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/admin/Documents/GitHub/RAPID/prototypes/ethquest/node_modules/@babel/runtime/helpers/esm/objectSpread2"));

var _slicedToArray2 = _interopRequireDefault(require("/Users/admin/Documents/GitHub/RAPID/prototypes/ethquest/node_modules/@babel/runtime/helpers/esm/slicedToArray"));

var _react = require("react");

var _Component = _interopRequireDefault(require("../Component"));

var ListObjectValues = function ListObjectValues(props) {
  var _useState = (0, _react.useState)(),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      list = _useState2[0],
      setList = _useState2[1];

  (0, _react.useEffect)(function () {
    if (props.data) {
      var deltas = Object.keys(props.data);
      setList(Object.values(props.data).map(function (item, index) {
        return (0, _objectSpread2["default"])({}, item, {
          delta: deltas[index],
          key: deltas[index]
        });
      }));
    }
  }, [props.data]);
  return list && Array.isArray(list) && list.length > 0 ? list.map(function (item) {
    return (0, _Component["default"])(props.component, (0, _objectSpread2["default"])({}, item, {}, props.pass));
  }) : null;
};

var _default = ListObjectValues;
exports["default"] = _default;