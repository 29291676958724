"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSelectEnabledStatus = void 0;

var _react = require("react");

/**
 * @function useSelectEnabledStatus
 */

/* --- Global --- */

/* --- Effect --- */
var useSelectEnabledStatus = state => {
  var [isEnabled, setEnabled] = (0, _react.useState)(false);
  var [isEnableRequested, setIsEnableRequested] = (0, _react.useState)(false);
  (0, _react.useEffect)(() => {
    if (state.isEnableRequested) setIsEnableRequested(true);
  }, [state.isEnableRequested]);
  (0, _react.useEffect)(() => {
    if (state.isEnableSuccess) setEnabled(true);
  }, [state.isEnableSuccess]);
  return {
    ready: isEnabled,
    requested: isEnableRequested
  };
};

exports.useSelectEnabledStatus = useSelectEnabledStatus;