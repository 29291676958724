import {RegionTop, RegionFooter} from '@regions';

const CanvasTemplate = ({sx, sxMain, styled, children, ...props}) => {
  return (
    <Atom.Flex column sx={{flex: 1, height: '100vh'}}>
      <RegionTop />
      <Atom.Flex
        column
        center
        gradient="sunset"
        direction={45}
        sx={{
          bg: 'blue',
          flex: 1,
          width: '100%',
          ...sx,
        }}
        {...props}>
        <Atom.BackgroundImage
          ratio={0.001}
          sx={{opacity: 1}}
          src="https://imgur.com/dNWeIX6.png"
        />
        {children}
      </Atom.Flex>
    </Atom.Flex>
  );
};

export default CanvasTemplate;
