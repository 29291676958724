/**
 * @name RegionTop
 * @description Default top region for application.
 */
/* --- Global --- */
import {Link} from 'gatsby';

/* --- Local --- */
import {ColorMode} from '@components/common';

export default props => (
  <Atom.Flex alignCenter between sx={{variant: 'regions.headerAbsolute'}}>
    {/* Left */}
    <Atom.Flex alignCenter sx={{flex: 1, width: '100%'}}>
      <Atom.Flex sx={{flex: 1}}>
        <Molecule.Link to="/">
          <Atom.Heading as="h5" sx={{mr: 3, mb: 0}}>
            {GLOBAL.siteName}
          </Atom.Heading>
        </Molecule.Link>
      </Atom.Flex>

      {/* Menu */}
      <Atom.Flex center sx={{flex: 1}}></Atom.Flex>

      {/* Right */}
      <Atom.Flex center sx={{flex: 1, justifyContent: 'flex-end'}}>
        <Atom.Box sx={{mx: 3}}>
          <ColorMode />
        </Atom.Box>
      </Atom.Flex>
    </Atom.Flex>
  </Atom.Flex>
);
