import React from 'react';
import {MDXRenderer} from 'gatsby-plugin-mdx';
import '@assets/css/Article.css';

/* --- Local --- */
export default function PageTemplate(props) {
  return (
    <>
      <Atom.Flex between column flex={1} minHeight="100vh">
        {props.pageContext.title && (
          <Atom.Box
            gradient={props.pageContext.frontmatter.gradient || 'green'}
            sx={{
              color: 'white',
              py: 6,
              px: 4,
              textAlign: 'center',
            }}>
            <Atom.Flex center column sx={{px: 4}}>
              <Atom.Box
                sx={{bg: 'white', p: 4, borderRadius: 999999, width: 180}}>
                <Atom.Image
                  src={props.pageContext.frontmatter.image}
                  sx={{width: '100%'}}
                />
              </Atom.Box>
              <Atom.Heading giga heavy>
                {props.pageContext.title}
              </Atom.Heading>
              {props.pageContext.subtitle && (
                <Atom.Heading xl thin>
                  {props.pageContext.subtitle}
                </Atom.Heading>
              )}
              <Atom.Box card circle sx={{p: 4, width: ['100%', '100%', 500]}}>
                {/* <Atom.Heading>Quest</Atom.Heading> */}
                <Atom.Paragraph sm sx={{m: 0}}>
                  {props.pageContext.frontmatter.summary}
                </Atom.Paragraph>
              </Atom.Box>
              {/* <Atom.Button white>Complete Quest</Atom.Button> */}
            </Atom.Flex>
          </Atom.Box>
        )}
        <Atom.Box
          as="main"
          sx={{
            flex: 1,
            px: 4,
            py: 5,
          }}>
          <Atom.Container
            sx={{
              maxWidth: ['100%', '100%', 760, 720],
            }}>
            {props.pageContext.frontmatter.url && (
              <Atom.Flex center column sx={{mb: 4}}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={props.pageContext.frontmatter.url}>
                  <Atom.Button lg white>
                    Discover {props.pageContext.frontmatter.title} | External
                    Site
                  </Atom.Button>
                </a>
              </Atom.Flex>
            )}
            <MDXRenderer>{props.pageContext.body}</MDXRenderer>
          </Atom.Container>
          <Atom.Container sx={{maxWidth: ['100%', '100%', 760, 720]}}>
            <Atom.Flex center column sx={{textAlign: 'center'}}>
              <Atom.Image
                card
                p1
                src={props.pageContext.frontmatter.featured}
              />
            </Atom.Flex>
          </Atom.Container>
        </Atom.Box>
      </Atom.Flex>
    </>
  );
}
