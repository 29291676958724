"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "combineReducers", {
  enumerable: true,
  get: function get() {
    return _combineReducers.combineReducers;
  }
});
Object.defineProperty(exports, "combineExtensionInitialState", {
  enumerable: true,
  get: function get() {
    return _combineExtensionInitialState.combineExtensionInitialState;
  }
});
Object.defineProperty(exports, "combineExtensionsReducers", {
  enumerable: true,
  get: function get() {
    return _combineExtensionsReducers.combineExtensionsReducers;
  }
});
Object.defineProperty(exports, "getExtensionsReducers", {
  enumerable: true,
  get: function get() {
    return _getExtensionsReducers.getExtensionsReducers;
  }
});
Object.defineProperty(exports, "getExtensionsInitialState", {
  enumerable: true,
  get: function get() {
    return _getExtensionsInitialState.getExtensionsInitialState;
  }
});

var _combineReducers = require("./combineReducers");

var _combineExtensionInitialState = require("./combineExtensionInitialState");

var _combineExtensionsReducers = require("./combineExtensionsReducers");

var _getExtensionsReducers = require("./getExtensionsReducers");

var _getExtensionsInitialState = require("./getExtensionsInitialState");