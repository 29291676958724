"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSelectContractList = void 0;

var _react = require("react");

var _system = require("@ethers-react/system");

/**
 * @function useSelectContractList
 */

/* --- Global --- */

/* --- Effect --- */
var useSelectContractList = state => {
  var ethers = (0, _system.withEthers)();
  var [isConnected, setIsConnected] = (0, _react.useState)(false);
  var [contracts, setContracts] = (0, _react.useState)();
  (0, _react.useEffect)(() => {
    setContracts(ethers.contracts);
  }, [ethers.contracts]);
  return {
    isConnected,
    list: contracts
  };
};

exports.useSelectContractList = useSelectContractList;