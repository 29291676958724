"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useWalletSignerGet = void 0;

var _react = require("react");

var _types = require("../types");

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

/* --- Effect --- */
var useWalletSignerGet = (state, dispatch) => {
  (0, _react.useEffect)(() => {
    if (state.reactive.getProviderSigner, state.provider) {
      _asyncToGenerator(function* () {
        try {
          var signer = yield state.provider.getSigner(state.address);
          dispatch({
            type: _types.SIGNER_GET_SUCCESS,
            payload: signer
          });
        } catch (error) {
          dispatch({
            type: _types.SIGNER_GET_FAILURE,
            payload: error
          });
        }
      })();
    }
  }, [state.provider]);
  return true;
};

exports.useWalletSignerGet = useWalletSignerGet;