"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getExtensionsInitialState = void 0;

var getExtensionsInitialState = extensions => extensions.map((_ref) => {
  var {
    name,
    initialState
  } = _ref;
  return {
    name,
    initialState
  };
}).reduce((acc, cur) => {
  acc[cur.name] = cur.initialState;
  return acc;
}, {});

exports.getExtensionsInitialState = getExtensionsInitialState;