"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SET_ADDRESS = exports.SET_WALLET = exports.SIGNER_GET_FAILURE = exports.SIGNER_GET_SUCCESS = exports.PROVIDER_SET_STATUS = exports.PROVIDER_SET_MULTIPLE = exports.PROVIDER_SET = exports.NONCE_SET = exports.NETWORK_CHANGE_SET = exports.NETWORK_SET = exports.ENS_ADDRESS_SET = exports.BLOCK_CURRENT_SET = exports.BALANCE_SET = void 0;
// * Set Actions
var BALANCE_SET = "BALANCE_SET";
exports.BALANCE_SET = BALANCE_SET;
var BLOCK_CURRENT_SET = "BLOCK_CURRENT_SET";
exports.BLOCK_CURRENT_SET = BLOCK_CURRENT_SET;
var ENS_ADDRESS_SET = "ENS_ADDRESS_SET";
exports.ENS_ADDRESS_SET = ENS_ADDRESS_SET;
var NETWORK_SET = "NETWORK_SET";
exports.NETWORK_SET = NETWORK_SET;
var NETWORK_CHANGE_SET = "NETWORK_CHANGE_SET";
exports.NETWORK_CHANGE_SET = NETWORK_CHANGE_SET;
var NONCE_SET = "NONCE_SET";
exports.NONCE_SET = NONCE_SET;
var PROVIDER_SET = "PROVIDER_SET";
exports.PROVIDER_SET = PROVIDER_SET;
var PROVIDER_SET_MULTIPLE = "PROVIDER_SET_MULTIPLE";
exports.PROVIDER_SET_MULTIPLE = PROVIDER_SET_MULTIPLE;
var PROVIDER_SET_STATUS = "PROVIDER_SET_STATUS"; // export const SIGNER_GET_REQUEST = "SIGNER_GET_REQUEST";

exports.PROVIDER_SET_STATUS = PROVIDER_SET_STATUS;
var SIGNER_GET_SUCCESS = "SIGNER_GET_SUCCESS";
exports.SIGNER_GET_SUCCESS = SIGNER_GET_SUCCESS;
var SIGNER_GET_FAILURE = "SIGNER_GET_FAILURE";
exports.SIGNER_GET_FAILURE = SIGNER_GET_FAILURE;
var SET_WALLET = "SET_WALLET";
exports.SET_WALLET = SET_WALLET;
var SET_ADDRESS = "SET_ADDRESS";
exports.SET_ADDRESS = SET_ADDRESS;