"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.walletSendTransactionRequest = void 0;

var _types = require("../types");

var walletSendTransactionRequest = dispatch => transaction => {
  dispatch({
    type: _types.WALLET_SEND_TRANSACTION_REQUEST,
    payload: transaction
  });
};

exports.walletSendTransactionRequest = walletSendTransactionRequest;